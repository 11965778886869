import AppHeader from "../../Comp/AppHeader";
import UserFeedback from "./UserFeedback";
import { FaArrowRightLong } from "react-icons/fa6";
import star from "./assets/star.svg";
import vpnPhone from "./assets/VPNPhone.svg";
import play from "./assets/playstore.svg";
import "./ProntoVPN.scss";
import Features from "./Features";
import Services from "./Services";
import MultipleServers from "./MultipleServers";
import {Pricing} from "./Pricing";
import {DownloadVPN} from "./DownloadVPN";
import FAQ from "./FAQ";

const ProntoVPN = ()=>{
    return(
        <>
     <div className="relative bg-black min-h-screen w-full overflow-hidden px-4 sm:px-6 lg:px-8">
    <AppHeader name="hdxvideoplayer" indexSlide={3} />
    <div className="w-[672px] h-[672px] rounded-[672px] bg-gradient-to-b from-[#8290FF] via-transparent to-[#0F0F10] rotate-[-45deg] blur-[219px] absolute top-0 left-0 -z-[10px]"></div>
    <img src={star} alt="Star" className="h-[100%] absolute top-0 left-0 -z-[10px] " />
    
    <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center justify-between z-[10px] ">
       
        <div className="lg:w-1/2 space-y-10">
          <div className="space-y-4">
            <span className="text-[#A5F5A9] text-2xl font-bold">Fast. </span>
            <span className="text-[#C79FFF] text-2xl font-bold">Free. </span>
            <span className="text-[#FF8EF7] text-2xl font-bold">Safe  </span>
            <div className="flex items-center space-x-16 pt-8">
          {/* Review */}
          <div>
          <div className="flex -space-x-4">
              <div className="w-8 h-8 rounded-full bg-gray-300" />
              <div className="w-8 h-8 rounded-full bg-gray-400" />
              <div className="w-8 h-8 rounded-full bg-gray-500" />
           </div>
           <div className="pt-2">
            <p className="text-white text-[40px] leading-[40px] font-bold mb-0">3.6 K+</p>
            <p className="text-[#797E86] text-[16px] font-medium">Reviews</p>
           </div>
          </div>
              {/* Rating */}
            <div>
            <div className="flex space-x-1">
              {[...Array(4)].map((_, i) => (
                <svg key={i} className="w-7 h-7 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              ))}
            </div>
            <div className=" pt-2">
            <p className="text-white text-[40px] leading-[40px] font-bold  mb-0">4.0</p>
            <p className="text-[#797E86] text-[16px] font-medium">Rating</p>
           </div>
            </div>
             {/* Downloads */}
            <div>
            <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 20.8827C4.90471 21.278 5.45361 21.5 6.02595 21.5H18.9741C19.5464 21.5 20.0953 21.278 20.5 20.8827M12.5012 3.5V15.4425M12.5012 15.4425L17.4338 10.8793M12.5012 15.4425L7.56859 10.8793" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/></svg>
            <div className=" pt-2">
            <p className="text-white text-[40px] leading-[40px] font-bold mb-0">1M+</p>
            <p className="text-[#797E86] text-[16px] font-medium">Downloads</p>
            </div>
            </div>
          </div>

            <h1 className="text-5xl lg:text-6xl font-bold text-white leading-[70px]">
              Your gateway to{' '}
              <span className="text-[#8290FF] leading-[70px]">unrestricted &<br />secure</span> browsing
            </h1>
            <p className="text-gray-400 text-lg max-w-xl ">
              Pronto VPN is the only tool you need for secure browsing
              and smooth streaming.
            </p>
          </div>
          {/* <div className="flex gap-8">
          <button className="bg-gradient-to-tl from-[#F767F2] via-[#8290FF] to-[#F767F2] text-white px-8 py-3 rounded-full hover:bg-[#6B7AE5] transition-colors flex gap-2 items-center ">
            <span>Get Pronto VPN</span> <FaArrowRightLong size={15} />
          </button>
          <img src={play} alt="Play Store "/>
          </div> */}
         
        </div>
        
        <div className="lg:w-1/2 mt-10 lg:mt-12">
          <div className="p-5">
            <img
                src={vpnPhone}
                alt="Pronto VPN Main Interface"
                className="shadow-xl object-cover"
              />
          </div>
        </div>
      </div>
    </div> 



    {/* <Features/>
    <Services/>
    <MultipleServers/>
    <Pricing/>
    <DownloadVPN/>
    <div className="bg-[#0F0F0F] w-screen ">
        <UserFeedback/>
        <FAQ/>
    </div>
     */}
    </>
    )
}
export default ProntoVPN;