import React from "react";
import { Link } from "react-router-dom";
import DownloadButton from "./DownloadButton";
import "../App.css";
const Apps = () => {
  const AppLogo = ({ image, name, heading, link1, link2, page, text }) => {
    return (
        <div className="flex flex-col items-center justify-center">
        <Link to={page}>
          <img src={image} alt={name} className="w-24 lg:w-32 h-24 lg:h-32 border-[3px] rounded-xl" />
        </Link>

        <div className="flex flex-col lg:h-20 items-center   justify-start mt-3 ">
          <h2 className="font-normal truncate lg:font-bold w-32 lg:w-72 text-sm  lg:text-[100%] text-center ">
            {name}
          </h2>
          <p className="!text-center w-32 lg:w-64 hidden lg:flex font-medium text-[50%] lg:text-[12px]">
            {heading}{" "}
          </p>
        </div>
        <div className="apps-install-now">
          <DownloadButton link1={link1} link2={link2} text={text}>
            {text}
          </DownloadButton>
        </div>
      </div>
     
    );
  };

  return (
    <>
      <div className="-mt-10   bg-gradient-to-br  lg:h-[1700px] relative z--50 text-white from-[#558CF7] to-[#2e6eed] ">
        <img
          src="/images/Mask Group 2@2x.png"
          className="absolute top-0 left-0 z-10  w-96"
          alt="Design"
        />
        <img
          src="/images/Mask Group 3@2x.png"
          className="absolute bottom-0 lg:bottom-20 right-0 	 z-10  w-[500px]"
          alt="Design"
        />
        <img
          src="/images/Artboard 1.png"
          className="absolute hidden lg:block -bottom-[400px]  z-10 object-center w-[2000px]"
          alt="Design"
        />
        <h2 className="text-3xl select-none	 relative z-30 text-white lg:text-5xl pt-16 lg:pt-20 text-center font-thin">
          Our <strong className="font-bold">Creations</strong>
        </h2>
        <div className="grid px-2 lg:px-32 py-20 gap-10 lg:gap-20 grid-cols-2 lg:grid-cols-3 relative z-20">
        <AppLogo
            image={"/images/icon/collagemaker.png"}
            heading="Remix and edit your beautiful photos with easy to use Collage maker"
            link1="https://play.google.com/store/apps/details?id=collagemaker.photoeditor.postcreator"
            link2="https://play.google.com/store/apps/details?id=collagemaker.photoeditor.postcreator"
            name="Collage Maker and Photo Editor"
            page=""
            text={"Install now"}
          />
               <AppLogo
            image={"/images/icon/hdx.jpg"}
            heading="Experience seamless video and music playback with HDx video player"
            link1={
              "https://play.google.com/store/apps/details?id=com.video.videoplayer.allformat"
            }
            link2="https://play.google.com/store/apps/details?id=com.video.videoplayer.allformat"
            name="Hdx Video Player"
            page="/hdxvideoplayer"
            text={"Install now"}
          />

          <AppLogo
            image={"/images/icon/videomaster.jpg"}
            heading="Make your videos stand out with aesthetic edits"
            link1={
              "https://play.google.com/store/apps/details?id=com.videomaster.editor"
            }
            link2={"https://play.google.com/store/apps/details?id=com.videomaster.editor"}
            name={`Video  Editor   Master`}
            page={""}
            text="Install now"
          />

         

         
          <AppLogo
            image={"/images/icon/zx.jpg"}
            heading="Easily organize, manage, and share files with fast, secure storage."
            link1="https://play.google.com/store/apps/details?id=com.filemanager.fileexplorer.junkcleaner"
            link2="https://play.google.com/store/apps/details?id=com.filemanager.fileexplorer.junkcleaner"
            name="ZX File Manager"
            page=""
            text={"Install now"}
          />
           <AppLogo
            image={"/images/icon/vpn.jpg"}
            heading="Secure your online activities with our VPN app."
            link1="https://play.google.com/store/apps/details?id=com.appspace.fast.secure.vpn"
            link2="https://play.google.com/store/apps/details?id=com.appspace.fast.secure.vpn"
            name="VPN Pronto"
            // page="/prontoVPN"
            page=""
            text={"Install now"}
          />
<AppLogo
            image={"/images/icon/calmverse.jpg"}
            heading="Unwind and relax with calming instrumental music and in-built live radio"
            link1="https://play.google.com/store/apps/details?id=com.calmsleep.android"
            link2="https://apps.apple.com/in/app/calmverse-relax-and-meditate/id1669727239"
            name="Calmverse"
            page="/calmverse"
            text={"Install now"}
          />

          {/* <AppLogo
            image={"/images/icon/Rectangle 34@2x.png"}
            heading="Discover, download and share collection of daily life Quotes"
            link1={
              "https://play.google.com/store/apps/details?id=app.life.quotes.android"
            }
            link2=""
            name="Quotes Creator"
            page=""
            text={"Install now"}
          /> */}

     
          <AppLogo
            image={"/images/icon/HQ.jpg"}
            link1="https://play.google.com/store/apps/details?id=com.simple.voice.audiorecorder"
            link2="https://play.google.com/store/apps/details?id=com.simple.voice.audiorecorder"
            heading="Record high-quality audio for meetings, lectures, and personal notes."
            name="HQ Recorder"
            page=""
            text={"Install now"}
          />

          

          <AppLogo
            image={"/images/icon/GPS.jpg"}
            heading="Capture photos with GPS coordinates for geotagging and travel documentation."
            link1="https://play.google.com/store/apps/details?id=com.geotag.gpscamera"
            link2="https://play.google.com/store/apps/details?id=com.geotag.gpscamera"
            name="GeoStamp"
            page=""
            text={"Install now"}
          />
           <AppLogo
            image={"/images/icon/war tank.jpg"}
            heading="Dominate the battlefield with our War Tank game."
            link1="https://play.google.com/store/apps/details?id=com.appspace.ballblast"
            link2="https://play.google.com/store/apps/details?id=com.appspace.ballblast"
            name="war tank"
            page=""
            text={"Install now"}
          />

          {/* <AppLogo image={"/images/icon/Rectangle 67@2x.png"} 
     heading="Relive your childhood with fun gameplay and multiple levels"
     link="https://play.google.com/store/apps/details?id=appspace.tictactoe.game"
     name="Tic Tac Toe"/>
     <AppLogo image={"/images/new/ball.jpg"} 
     heading="Relive your childhood with fun gameplay and multiple levels"
     link="https://play.google.com/store/apps/details?id=com.dropstackball.game"
     name="Stack Ball Game "/> */}
        </div>

        {/* <div className="flex justify-center items-center p-5 lg:p-8">

        <a href="https://play.google.com/store/apps/dev?id=5808132673176532737&hl=en-IN">
        <div className="apps-install-now px-4 py-4 flex ">
           Explore More
        </div>
        </a>
        </div>
       */}
       
      </div>
    </>
  );
};

export default Apps;
