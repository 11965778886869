import Lottie from "lottie-react";
import React from "react";
import animationData from "../../images/calmverse/animations/5ZKT36Ky0I.json";
import "./CalmVerse.scss";

import icon1 from "../../images/calmverse/images/icon1.svg";
import icon2 from "../../images/calmverse/images/icon2.svg";
import icon3 from "../../images/calmverse/images/icon3.svg";
import playstore from "../../images/calmverse/images/playstore_icon.png";
import appstore from "../../images/calmverse/images/app_store_icon.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import book from "../../images/calmverse/images/Group 4422.svg";
import DownloadButton from "../../Comp/DownloadButton";
import FooterNew from "../../Comp/FooterNew";
import AppHeader from "../../Comp/AppHeader";
import top_left from "../../images/calmverse/images/top-left.png";
import top_right from "../../images/calmverse/images/top-right.png";
import bottom_right from "../../images/calmverse/images/bottom-right.png";
import bottom_left from "../../images/calmverse/images/bottom-left.png";
const CalmVerse = () => {
  return (
    <>
      <AppHeader name="calmverse" indexSlide={2} />

      <div className="container-calm">
        <div className="bg">
          <div className="animation">
            <div className="left-animation">
              <Lottie animationData={animationData} />
            </div>
            <div className="right-animation">
              <Lottie animationData={animationData} />
            </div>
          </div>
        </div>

        <div className="heading">
          <h1>
            Drift into calmness where dreams find a haven and restless nights
            become peaceful.
          </h1>
          <p>
            Calmverse aims to provide relaxed sleep and a peaceful mind. <br />{" "}
            Drift into Calmness where dreams find a haven and restless nights
            become peaceful.
          </p>
        </div>
        <div className="download-now">
          <a
            href="https://play.google.com/store/apps/details?id=com.calmsleep.android"
            target="_blank"
            rel="noreferrer"
          >
            <img src={playstore} alt="" />
          </a>
          <a
            href="https://apps.apple.com/in/app/calmverse-relax-and-meditate/id1669727239"
            target="_blank"
            rel="noreferrer"
          >
            <img src={appstore} alt="" />
          </a>
        </div>
      </div>
      <div className="blur-effect"></div>
      <div className="main-content">
        <img
          src={top_left}
          alt=""
          width={200}
          height={350}
          className="absolute left-0 side-leaf-top "
        />
        <img
          src={top_right}
          alt=""
          width={200}
          height={350}
          className="absolute right-0 side-leaf-top"
        />
        <div className="animation">
          <div className="left-animation">
            <Lottie animationData={animationData} />
          </div>
          <div className="right-animation">
            <Lottie animationData={animationData} />
          </div>
        </div>
        <div className="sub-heading ">
          <h2>Music that calm your mind.</h2>
          <h2>Helps you sleep Deeper and Faster.</h2>
        </div>
        <div className="sub-heading-text">
          <p className=" ">
            Calmverse aims to provide relaxed sleep and a peaceful mind. <br />
            Drift into Calmness where dreams find a haven and restless nights
            become peaceful. <br /> Experience soothing and relaxing sounds
            tailored just for you.
          </p>
        </div>

        <DownloadButton
          link1="https://play.google.com/store/apps/details?id=com.calmsleep.android"
          link2="https://apps.apple.com/in/app/calmverse-relax-and-meditate/id1669727239"
          text="Get it for free"
        ></DownloadButton>

        <div className="services">
          <h2>Our Services and What we offer</h2>
          <div className="icons">
            <div className="icon">
              <img
                src={icon1}
                alt="custom sounds"
                width="80vmax"
                height="80vmax"
              />
              <h2>Customization Sounds</h2>
              <p>
                In the calmverse app, you can explore the Custom section where
                there are multiple soothing sounds like rain music, snowfall,
                birds chirping, and much more. So which sound you prefer click
                on it and click on other sounds you want to add and you will get
                various soothing sounds in one music.
              </p>
            </div>
            <div className="icon">
              <img
                src={icon2}
                alt="meditation sounds"
                width="80vmax"
                height="80vmax"
              />
              <h2>Meditation </h2>
              <p>
                In explore section you can now practice the meditation with
                peaceful music and sound. Here you can flow in beautiful
                imagination of stories which will help you to relax and practice
                the mindful meditation
              </p>
            </div>
            <div className="icon">
              <img
                src={icon3}
                alt="healthy tips"
                width="80vmax"
                height="80vmax"
              />
              <h2>Healthy Sleep Tips</h2>
              <p>
                With sleeping sounds, and stories you can also read short sleep
                tips where you can see some informative tips to enhance your
                sleep, how to cope up with depression, anxiety, and much more.
                You can also read how mindfulness eating, and meditating can
                improve your sleep, and much more.
              </p>
            </div>
          </div>
        </div>
        <div className="middle-banner ">
          <div className="middle-banner-content w-[26vmax]">
            <img src={book} alt="" className="mb-4" />
            <h1 className="mb-6 ">Magical Stories</h1>
            <p>
              Just tired at the end of the day? The best solution is to listen
              to peaceful magical stories. You can hit nostalgia by listening to
              these beautiful stories as you can listen to them and go into a
              magical sleep. These stories are also best for your children as
              here you get multiple short stories that you can recite to your
              children at night.
            </p>
          </div>
        </div>
        <div className="faq flex flex-col justify-center items-center ">
          <img
            src={bottom_right}
            alt=""
            width={200}
            height={350}
            className="absolute right-0 mt-20 side-leaf-bottom"
          />
          <img
            src={bottom_left}
            alt=""
            width={200}
            height={350}
            className="absolute left-0  mt-20 side-leaf-bottom"
          />

          <h1>Fairy Answering Questions</h1>
        </div>
        <div className="faq-accordion flex flex-col justify-center items-center mt-16">
          <Accordion
            className=" text-[1.3vmax] faq-accordion-container "
            allowZeroExpanded={true}
          >
            <AccordionItem className=" accordion-item rounded-xl mb-5 p-6">
              <AccordionItemHeading className=" ">
                <AccordionItemButton
                  className=" accordion-question !bg-transparent
                    
                   !rounded-lg font-semibold"
                >
                  How can I download the Calmverse app?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion-answer">
                You can easily download the calmverse app from Google Play Store
                or App Store.
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className=" accordion-item rounded-2xl mb-5 p-6 ">
              <AccordionItemHeading className=" ">
                <AccordionItemButton
                  className=" accordion-question !bg-transparent
                   !rounded-lg font-semibold"
                >
                  Who should use the Calmverse app?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion-answer">
                The Calmverse app is for everyone who wants calm sleep, and
                relaxing sounds. It can be used by adults and children as well.
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className=" accordion-item rounded-2xl mb-5 p-6">
              <AccordionItemHeading className=" ">
                <AccordionItemButton
                  className=" accordion-question !bg-transparent
                    
                   !rounded-lg font-semibold"
                >
                  What features are there in Calmverse?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion-answer">
                There are multiple features you can explore such as
                customization, magical stories, tips, and much more.
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className=" accordion-item rounded-2xl mb-40 p-6">
              <AccordionItemHeading className=" ">
                <AccordionItemButton
                  className=" accordion-question !bg-transparent
                    
                   !rounded-lg font-semibold"
                >
                  Is Calmverse app just for Android?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="accordion-answer">
                No, the Calmverse app is for Android and iOS as well.
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <FooterNew bgColor="#1A6720" />
    </>
  );
};

export default CalmVerse;
