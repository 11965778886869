import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";

const Carousel = ({ setIndex }) => {
  const [showTop, setShowTop] = useState(true);
  const [inSlide, setInSlide] = useState(0);
  const slideRef = useRef();

  const settings = {
    dots: true,
    autoplay: true,
    infinte: true,
    speed: 2000,
    slidesToShow: 1,
    centerPadding: "100px",
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    beforeChange: (oldIndex, newIndex) => {
      console.log(`NewIndex is ${newIndex} oldIndex is ${oldIndex}`);
      switch (newIndex) {
        case 0:
          setIndex(0);
          setInSlide(0);
          break;
        case 1:
          setIndex(1);
          setInSlide(1);
          break;
        case 2:
          setIndex(2);
          setInSlide(2);
          break;
        // case 3:
        //   setIndex(3);
        //   setInSlide(3);
        //   break;
        default:
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="relative overflow-hidden">
      <div className="absolute flex space-x-6 items-center justify-center bottom-4 left-0 right-0 m-auto z-30">
        <div
          className={`text-center cursor-pointer ${
            inSlide === 0 ? "bg-[#FF6200]" : "bg-gray-200"
          } transition-all rounded-full h-2 w-2 `}
          onClick={() => {
            console.log("0 Slide");

            slideRef.current.slickGoTo(0, true);
          }}
        ></div>
        <div
          className={`text-center cursor-pointer ${
            inSlide === 1 ? "bg-[#FF6200]" : "bg-gray-200"
          } transition-all rounded-full h-2 w-2 `}
          onClick={() => slideRef.current.slickGoTo(1, true)}
        ></div>
        <div
          className={`text-center cursor-pointer ${
            inSlide === 2 ? "bg-[#FF6200]" : "bg-gray-200"
          } transition-all rounded-full h-2 w-2 `}
          onClick={() => slideRef.current.slickGoTo(2, true)}
        ></div>
        {/* <div
          className={`text-center cursor-pointer ${
            inSlide === 3 ? "bg-[#FF6200]" : "bg-gray-200"
          } transition-all rounded-full h-2 w-2 `}
          onClick={() => slideRef.current.slickGoTo(3, true)}
        ></div> */}
      </div>
      <Slider ref={slideRef} {...settings} className="relative">
        <div className="pt-0 -mt-4 ">
          <div className=" px-10 flex items-center justify-start pl-20 lg:pl-28 relative  h-[450px] lg:h-[500px]">
            <div className="text-[#d8abab] -mt-4 z-20">
              <h1 className="text-[18px] lg:text-5xl text-black hidden lg:block font-bold">
                Coming{" "}
              </h1>
              <h1 className="text-[18px] lg:text-5xl text-black block lg:hidden font-bold">
                Coming{" "}
                <strong className="text-[#558CF7] ">to your space</strong> with
              </h1>
              <h1 className="text-[18px] lg:text-5xl hidden lg:block text-[#FF6200] font-bold">
                <strong className="text-[#558CF7] ">to your space</strong> with
              </h1>
              <h1 className="text-[18px] text-center text-black  lg:text-5xl font-bold">
                <strong className="text-[#FF6200]">top-notch</strong>{" "}
                applications
              </h1>
              <p className=" text-[16px] lg:text-3xl font-thin mt-2 lg:text-left text-center lg:mt-6">
                <strong className="text-black">Android | iOS | Unity</strong>
              </p>
              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://play.google.com/store/apps/dev?id=5808132673176532737"
                className="select-none	"
              >
                <img
                  src="/images/icon/google-play-badge.png"
                  className="w-32 select-none	 lg:m-0 m-auto lg:w-44 mt-2 lg:mt-4"
                  alt="google play store"
                />
              </a>
            </div>
            <div>
              <img
                src="/images/new/home bg.png"
                alt="carousel"
                className=" mt-10 absolute bottom-0 right-0 lg:-right-32 object-cover lg:h-[400px] h-[200px]  w-[1000px] lg:w-[1000px]"
              />
            </div>
          </div>
        </div>

        {/* Video Editor Master */}

        <div
          className="min-h-full relative text-white  h-[450px] lg:h-[500px]"
          onMouseEnter={() => {
            if (showTop === false) {
              return;
            }

            setTimeout(() => {
              setShowTop(false);
            }, 1000);
          }}
        >
          <div className="absolute  justify-center   items-center flex lg:items-start pl-0 lg:pl-20 flex-col  lg:pt-0 -top-32 lg:top-0 bottom-0 my-auto">
            <div className="flex items-center space-x-5 justify-center">
              <img
                src="/images/new/Group 14.png"
                className="w-[150px]  ml-10 lg:w-[400px] "
                alt="Aesthetic Videos are just one step away"
              />
              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.videomaster.editor"
                className="z-20 select-none"
              >
                <img
                  src="/images/icon/google-play-badge.png"
                  className="w-32 lg:hidden select-none	  lg:w-44 -mt-3 lg:mt-4"
                  alt="google play store"
                />
              </a>
            </div>
            <div className="lg:flex flex-col hidden  lg:flex-row items-start lg:items-center  mt-6 ml-0 lg:ml-4 justify-start lg:justify-center">
              <div className="lg:flex items-center hidden  justify-center ">
                <img
                  src="/images/icon/Rectangle 17@2x.png"
                  alt="Video Editor Master"
                  className="w-10 lg:w-16 m-1 lg:m-4 "
                />
                <h2 className="w-20 lg:w-24 text-[10px] lg:text-[14px] font-medium text-black">
                  Video Editor Master
                </h2>
              </div>
              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.videomaster.editor"
              >
                <img
                  src="/images/icon/google-play-badge.png"
                  className="w-32 lg:w-44 select-none	 -mt-3 lg:mt-4"
                  alt="google play store"
                />
              </a>
            </div>
          </div>
          <div
            className={`bg-white bg-opacity-25 hidden lg:block w-[40%] rounded-b-lg ${
              showTop === false ? "h-14" : "h-0"
            } transition-all absolute top-16 left-0 right-0 mx-auto `}
          >
            <h2
              className={`text-center pt-2  ajay   transition-all ${
                showTop === false ? "block text-3xl" : "hidden text-[1px] "
              } animate-pulse `}
            >
              Explore New
            </h2>
          </div>
          <img
            src="/images/new/edit.png"
            alt="hand"
            className="w-[370px] lg:w-[50%]  absolute -bottom-10 object-cover   
     lg:-bottom-32  left-0  m-auto right-0 lg:hidden  lg:right-0"
          />
          <img
            src="/images/new/edit.png"
            alt="hand"
            className="w-[370px] lg:w-[50%]  absolute -bottom-10 object-cover   
     lg:-bottom-32 hidden lg:block  m-auto right-0"
          />
          <div className="bg-white h-[550px]"></div>
        </div>

        {/* TODO CalmSleep */}

        {/* KNIFEHIT GAMES */}

        <div className="relative bg-knife-hit-banner  lg:bg-center bg-cover lg:bg-contain bg-no-repeat  h-[450px] lg:h-[500px] ">
          <div className="absolute -top-32 bottom-0  left-0  right-0 pl-4 lg:pl-20 pt-0 lg:pt-20 flex flex-col items-start justify-center">
            <div className="knifeHitName ">
              <h3>Knife Show</h3>
              <p>Hit the Target and Beat the Boss</p>
            </div>

            <div className="flex w-full   flex-row items-center mt-6 space-x-4 justify-center lg:justify-start">
              <button
                onClick={() =>
                  window.open(
                    "https://play.amezgame.com/knifeshow/index.html",
                    "_blank"
                  )
                }
                className="knifeHitPlayNow"
              >
                Play Now
              </button>
              <a
                href="https://play.google.com/store/apps/details?id=com.appspace.knifehit"
                className="select-none	"
                rel="noreferrer"
                target={"_blank"}
              >
                <img
                  src="/images/icon/google-play-badge.png"
                  className="w-32 lg:w-44 m-0  select-none	"
                  alt="google play store"
                />
              </a>
            </div>
          </div>
        </div>

        {/* StackBall */}

        {/* <div className="relative  h-[450px] lg:h-[500px]">
          <div className="absolute -top-32 lg:top-0 mx-10 bottom-0 pl-0 lg:pl-16 flex flex-col items-start lg:items-start   justify-center">
            <div className="flex items-center lg:justify-start justify-center">
              <img
                src="/images/new/Group 6.png"
                className="w-[60%] lg:w-[50%] "
                alt="StackBall Text"
              />
              <a
                href="https://play.google.com/store/apps/details?id=com.dropstackball.game"
                className="select-none	"
                rel="noreferrer"
                target={"_blank"}
              >
                <img
                  src="/images/icon/google-play-badge.png"
                  className="w-32 lg:hidden select-none	 lg:w-44 mt-4"
                  alt="google play store"
                />
              </a>
            </div>

            <div className="lg:flex flex-col hidden lg:flex-row  items-center mt-6 space-x-4 justify-center">
              <div className="lg:flex items-center hidden justify-center ">
                <img
                  src="/images/new/ball.jpg"
                  alt="Stack Ball"
                  className="w-10 lg:w-16 rounded-xl m-4 "
                />
                <h2 className="w-16 font-semibold text-white ">
                  Stack <br /> Ball{" "}
                </h2>
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=com.dropstackball.game"
                className="select-none	"
                rel="noreferrer"
                target={"_blank"}
              >
                <img
                  src="/images/icon/google-play-badge.png"
                  className="w-32 select-none	 lg:w-44 mt-4"
                  alt="google play store"
                />
              </a>
            </div>
          </div>
          <img
            src="/images/new/stack Ball.png"
            className=" absolute bottom-0 lg:hidden  left-[20%]    right-0 h-[250px] lg:h-[550px] "
            alt="Stack Ball"
          />
          <img
            src="/images/new/stack Ball.png"
            className=" absolute bottom-0 hidden lg:block    right-0 h-[250px] lg:h-[550px] "
            alt="Stack Ball"
          />
          <img
            src="/images/new/bg.png"
            alt="Stackball "
            className="h-full w-full"
          />
        </div> */}
      </Slider>
    </div>
  );
};

export default Carousel;
