import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Privacy from "./Screens/Privacy";
import AppSpaceHomePage from "./Screens/AppSpaceHomePage";
import AboutUsPage from "./Screens/AboutUsPage";
import ContactPage from "./Screens/ContactPage";
import FaqScreen from "./Screens/FaqScreen";
import FaqAll from "./Comp/FaqAll";
import CollageMakerFaq from "./Comp/CollageMakerFaq";
import VideoEditorFaq from "./Comp/VideoEditorFaq";
import CalmSleepFaq from "./Comp/CalmSleepFaq";
import Blogs from "./Screens/Blogs";
import DynamicBlog from "./Screens/DynamicBlog";
import KnifeHitFaq from "./Comp/KnifeHitFaq";
import Games from "./Screens/Games/Games";
import CalmVerse from "./Screens/CalmVerse/CalmVerse";
import Hdx from "./Screens/HdxVideoPlayer/Hdxvideo";
import ScrollToTop from "./Comp/ScrollToTop";
import ZXFileManager from "./Screens/PrivacyPolicy/ZXFileManager";
import ProntoVPN from "./Screens/Vpn/ProntoVPN";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<AppSpaceHomePage />} />
        <Route path="/faq" element={<FaqScreen />}>
          <Route index element={<FaqAll />} />
          <Route path="collage-maker" element={<CollageMakerFaq />} />
          <Route path="video-editor" element={<VideoEditorFaq />} />
          <Route path="calm-sleep" element={<CalmSleepFaq />} />
          <Route path="knife-hit" element={<KnifeHitFaq />} />
        </Route>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/privacy/zx-file-manager" element={<ZXFileManager/>} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:blogId" element={<DynamicBlog />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/games" element={<Games />} />
        <Route path="/calmverse" element={<CalmVerse />} />
        <Route path="/hdxvideoplayer" element={<Hdx/>} />
        <Route path="/prontoVPN" element={<ProntoVPN/>}/>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
